*{
	box-sizing: border-box;
}

$white: #fff;
$green: #12b750;
$red: #e52730;
$lime: #56ff1f;
$orange: #d17a22;
$blue: #4574b7;
$darkerGrey: #0e141b;
$darkGrey: #151f28;
$midGrey: #2d4556;
$lightGrey: #bbb;

body {
	margin: 0;
	padding: 0;
	background: $darkerGrey;
	font-family: "Interstate", sans-serif;
	font-weight: 400;
	color: $white;
}

.heading{
	font-family: "Rift", sans-serif;
	font-weight: 500;
}

.transition{
	transition: all 0.3s ease-in-out;
}

h1,h2,h3,h4,h5,h6{
	@extend .heading;

	strong{
		font-weight: 700;
	}
}

h1{
	font-size: 32px;
	color: $blue;
	line-height: 1;

	@media only screen and (max-width: 767px) {
		font-size: 20px;
	}
}

h2{
	font-size: 24px;
	color: white;

	@media only screen and (max-width: 767px) {
		font-size: 14px;
	}
}

.pro{
	color: $green;
}

.am{
	color: $orange;
}

.container{
	max-width: 100%;

	.flex{
		.col{
			max-width: 100%;

			&.full{
				width: 100%;
			}
		}
	}
}

ul{
	display: flex;

	@media only screen and (max-width: 767px) {
		font-size: 12px;
	}

	li{
		@extend .heading;
		font-size: 18px;

		@media only screen and (max-width: 767px) {
			font-size: 14px;
		}

		&.No{
			color: darken($white, 40%);
		}
	}

	&.react-tabs__tab-list{
		margin-bottom: 0;
		border-bottom: 2px solid $midGrey;

		@media only screen and (max-width: 767px) {
			padding: 0 .5rem;
			margin-top: 1rem;
		}

		li{
			padding: 1rem;
			margin-bottom: -2px;
			cursor: pointer;
			border-bottom: 2px solid $midGrey;
			@extend .transition;
			outline: none;
			color: $lightGrey;
			font-size: 1.2rem;

			@media only screen and (max-width: 767px) {
				padding: .5rem;
				font-size: 14px;
			}

			&.react-tabs__tab--selected{
				border-bottom-color: $white;
				color: $white;
			}

			&.blue{
				color: $blue;
			}

			&.lime{
				color: $lime;
			}

			&.pro{
				color: $green;
			}

			&.am{
				color: $orange;
			}
		}

		&.sub{
			margin-bottom: 2rem;

			li{
				font-size: 1rem;

				&.react-tabs__tab--selected{
					border-bottom-color: $lightGrey;
				}
			}
		}
	}
}

.react-tabs__tab-panel{
	position: relative;

	&:before{
		content: "";
		width: 24px;
		height: 24px;
		position: absolute;
		top: 130px;
		left: 1.3rem;
		animation-name: spin;
		animation-duration: 2000ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
		display: none;
		border: 2px solid $green;
		border-radius: 50%;
		border-top: 2px solid $darkerGrey;
	}

	&.react-tabs__tab-panel--selected{
		&:before{
			display: block;
		}
	}

	&.main{
		&:before{
			display: none;
		}
	}

	@keyframes spin {
		from {
				transform: rotate(0deg);
		}
		to {
				transform: rotate(360deg);
		}
	}
}

.overallTable{
	display: block;
	width: 100%;
	overflow-x: auto;
	overflow-y: visible;
	padding: 1.5rem 0;
	position: relative;

	.table{
		font-size: 14px;
		white-space: nowrap;
		text-align: left;
		width: 100%;
		table-layout: auto;

		thead{
			tr{
				th{
					@extend .heading;
					font-size: 18px;
					font-weight: 700;
					color: $blue;
					padding: .5rem;
					position: relative;
					cursor: pointer;

					@media only screen and (max-width: 767px) {
						font-size: 14px;
					}

					&.touring{
						color: #93c47d;
					}

					&.historic{
						color: #ffd966;
					}

					&.endurance{
						color: #ff9900;
					}

					&.grow{
						width: 100%;
					}

					span{
						display: none;
						position: absolute;
						top: -20px;
						left: 50%;
						transform: translatex(-50%);
						font-size: 18px;
						background: $midGrey;
						padding: .1rem .5rem;
						border-radius: 12px;

						&:after{
							content: "";
							width: 0;
							height: 0;
							border-left: 5px solid transparent;
							border-right: 5px solid transparent;
							border-top: 5px solid $midGrey;
							position: absolute;
							bottom: -5px;
							left: 50%;
							transform: translateX(-50%);
						}
					}

					&:hover{
						span{
							display: inline-block;
						}
					}

					&:first-child{
						padding-left: 0;
					}
				}
			}
		}

		tbody{

			&:nth-child(odd){
			}

			tr{
				border-bottom: 1px solid $darkGrey;
				background: $darkerGrey;

				&:hover{
					background: $darkGrey;
				}

				td{
					@extend .heading;
					font-weight: 500;
					padding: .5rem;
					color: white;
					font-size: 18px;

					@media only screen and (max-width: 767px) {
						font-size: 14px;
					}

					&:first-child{
						padding-left: 0;
					}

					&.grow{
						width: 100%;

						&:after{
							content: "PRO";
							margin-left: 1rem;
							color: white;
							font-style: italic;
							font-weight: 700;
							display: inline-block;
							padding: .1rem .5rem;
							border-radius: 14px;
							font-size: 13px;
							background: #008d47;
						}
					}

					img{
						border-radius: 5px;
						display: inline-block;
						max-width: 60px;
						margin-right: 1rem;

						@media only screen and (max-width: 767px) {
							max-width: 50px;
						}
					}

					span{
						background: $midGrey;
						display: inline-block;
						padding: .1rem .5rem;
						border-radius: 14px;
						color: $white;

						&.number{
							background: none;
							padding: 0;
							border-radius: 0;
							font-weight: 700;
							font-style: italic;
							margin-right: 1rem;
						}
					}
				}

				&.am{
					td.grow{
						&:after{
							content: "AM";
							margin-left: 1rem;
							color: white;
							font-style: italic;
							font-weight: 700;
							display: inline-block;
							padding: .1rem .5rem;
							border-radius: 14px;
							font-size: 13px;
							background: #d17a22;
						}
					}
				}
			}
		}
	}

// 	&.overall{
// 		.table{
// 			tbody{
// 				tr:first-child{
// 					td.grow{
// 						&:after{
// 							content: "Champion";
// 							margin-left: 1rem;
// 							color: black;
// 							font-style: italic;
// 							font-weight: 700;
// 							background: gold;
// 						}
// 					}
// 				}
// 				tr:nth-child(6){
// 					td.grow{
// 						&:after{
// 							content: "AM Champion";
// 							margin-left: 1rem;
// 							color: white;
// 							font-style: italic;
// 							font-weight: 700;
// 							background: #d17a22;
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
//
// 	&.am{
// 		.table{
// 			tbody{
// 				tr:first-child{
// 					td.grow{
// 						&:after{
// 							content: "AM Champion";
// 							margin-left: 1rem;
// 							color: white;
// 							font-style: italic;
// 							font-weight: 700;
// 							background: #d17a22;
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}

	&.rounds{
		.table{
			tbody{
				tr.prize{
					td.grow{
						&:after{
							display: none;
						}
					}
				}
			}
		}
	}
}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
